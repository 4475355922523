import { PropsWithChildren } from "react";

export default function LabelInput({ children }: PropsWithChildren) {
  return (
    <label
      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      htmlFor="grid-first-name"
    >
      {children}
    </label>
  );
}
