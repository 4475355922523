import { useEffect } from "react";
import Input from "../form-components/Input";
import { SubStepperProps } from "../type";
import Button from "../form-components/Button";
import { useQuery } from "@tanstack/react-query";
import { DEBUG } from "../MultiStep";
import { useForm } from "react-hook-form";
import Field from "../form-components/Field";
import { SideLogo } from "../form-components/SideLogo";
import { InputSuggestion } from "../form-components/InputSuggestion";
import { fetchAddress } from "../form-components/InputAdress";
import { useDebounce } from "@uidotdev/usehooks";
import { LoaderCircle } from "lucide-react";

interface GpsResponse {
  features: Array<{
    geometry: { coordinates: [number, number] };
  }>;
}

const fetchGPSData = async (
  address: string,
  plz: string,
  city: string
): Promise<GpsResponse> => {
  if (!DEBUG) {
    const url = `https://gisco-services.ec.europa.eu/api?lang=en&limit=1&q=${encodeURIComponent(
      `${address} ${city} ${plz}`
    )}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const data = await response.json();

    if (
      !data.features ||
      data.features.length === 0 ||
      !data.features[0].geometry ||
      !data.features[0].geometry.coordinates
    ) {
      throw new Error(
        "Nous n'avons pas pu trouver votre adresse. Veuillez reessayer."
      );
    }

    return data;
  } else {
    return {
      features: [
        {
          geometry: {
            coordinates: [1.4584659, 43.6490254],
          },
        },
      ],
    };
  }
};

export default function AdressStep({
  nextStep,
  previousStep,
  Data,
  updateData,
}: SubStepperProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm();

  const formData = watch();

  const { data, isSuccess, isError, error, isLoading } = useQuery({
    queryKey: ["fetchGPSData", formData.address, formData.plz, formData.city],
    queryFn: () => fetchGPSData(formData.address, formData.plz, formData.city),
    retry: false,
    enabled: isSubmitSuccessful,
  });

  useEffect(() => {
    if (isError) {
      reset({
        ...getValues(),
        isSubmitSuccessful: false,
      });
    }
  }, [isSubmitSuccessful, isError, reset, getValues]);
  const debouncedAddress = useDebounce(getValues("address"), 500);

  const {
    data: dataAddress,
    isSuccess: isSuccessAddress,
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
    error: errorAddress,
    refetch: refetchAddress,
  } = useQuery({
    queryKey: ["fetchAddress", debouncedAddress],
    queryFn: () => fetchAddress(getValues("address") || ""),
    enabled: true,
  });

  if (isSuccess && isSubmitSuccessful) {
    updateData({
      ...Data,
      coordonnees: {
        latitude: data.features[0].geometry.coordinates[0],
        longitude: data.features[0].geometry.coordinates[1],
      },
    });
    console.log("next step");
    nextStep();
  }

  const onSubmit = (data: any) => {
    updateData({ ...Data, ...data });
  };

  return (
    <SideLogo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap mb-6">
          <Field label="Adresse" error={errors?.address}>
            <InputSuggestion
              {...register("address", {})}
              defaultValue={Data.address}
              isError={!!errors?.address}
              suggestions={dataAddress}
              onClickSuggestion={(value) => {
                setValue("address", value.address);
                setValue("plz", value.plz);
                setValue("city", value.city);
              }}
              placeholder="Adresse"
              icon={
                isLoadingAddress && (
                  <div className="animate-spin">
                    <LoaderCircle />
                  </div>
                )
              }
            />
          </Field>
          <Field label="Code postal" error={errors?.plz}>
            <Input
              {...register("plz", {
                required: "Ce champ est obligatoire",
              })}
              type="number"
              min={0}
              step={1}
              placeholder="Code postal"
              defaultValue={Data.plz}
              isError={!!errors?.plz}
            />
          </Field>

          <Field label="Ville" error={errors?.city}>
            <Input
              {...register("city", {
                required: "Ce champ est obligatoire",
              })}
              placeholder="Ville"
              defaultValue={Data.city}
              isError={!!errors?.city}
            />
          </Field>
        </div>
        {isError && <div className="text-red-500 mb-4">{error.message}</div>}
        <div className="grid grid-cols-2">
          <Button onClick={() => previousStep()} disabled={isLoading}>
            Retour
          </Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Chargement..." : "Suivant"}
          </Button>
        </div>
      </form>
    </SideLogo>
  );
}
