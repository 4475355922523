import Button from "./form-components/Button";

interface MailButtonProps {
  subject: string;
  body: string;
  to: string;
}
function MailButton({ subject, body, to }: MailButtonProps) {
  const sendEmail = () => {
    const emailTo = encodeURIComponent(to);
    const emailSubject = encodeURIComponent(subject);
    const emailBody = encodeURIComponent(body);
    window.open(`mailto:${emailTo}?subject=${emailSubject}&body=${emailBody}`);
  };

  return <Button onClick={sendEmail}>Prendre un rendez-vous</Button>;
}

export default MailButton;
