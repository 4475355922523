import { InputHTMLAttributes, forwardRef } from "react";

export interface ToogleInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}

export const ToogleInput = forwardRef<HTMLInputElement, ToogleInputProps>(
  ({ isError, ...props }, ref) => {
    return (
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" className="sr-only peer" ref={ref} {...props} />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-green-300  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green"></div>
      </label>
    );
  }
);

export default ToogleInput;
