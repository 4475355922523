import { SubStepperProps } from "../type";
import Button from "../form-components/Button";
import { useQuery } from "@tanstack/react-query";

import { DEBUG } from "../MultiStep";
import MailButton from "../MailButton";
import {
  AmortizationTable,
  genereateAmortizationTable,
  getInvestissementBalancePositive,
} from "../AmortizationTable";
import { useState } from "react";
import { SideLogo } from "../form-components/SideLogo";

const orientationToPole = (orientation: string) => {
  switch (orientation) {
    case "0":
      return "Sud";
    case "45":
      return "Sud-Ouest";
    case "-45":
      return "Sud-Est";
    case "-90":
      return "Est";
    case "90":
      return "Ouest";
    case "125":
      return "Nord-Ouest";
    case "-125":
      return "Nord-Est";
    case "180":
      return "Nord";
    default:
      return "Sud";
  }
};
const fetchGetPrice = async (data: any): Promise<any> => {
  if (!DEBUG) {
    const url = `${process.env.REACT_APP_URL_BACKEND}/get_price`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Indique que le type de contenu est du JSON
      },
      body: JSON.stringify({ data }),
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return await response.json();
  } else {
    return {
      name: "Dupont",
      firstname: "Fabien",
      address: "25 rue frédéric chopin",
      plz: "31200",
      city: "Toulouse",
      coordonnees: {
        latitude: 1.4584659,
        longitude: 43.6490254,
      },
      typeCourant: "monophase",
      consommationAnnuelle: 3000,
      email: "fabien@cool.com",
      phone: "0612345678",
      isPower: false,
      toitures: [
        {
          longueur: "5",
          largeur: "5",
          orientation: "180",
          typeToiture: "tuileMecanique",
          ombreToiture: false,
          nb_panels: 6.0,
          E_y: 1380.23,
          nb_panels_use: 6.0,
          peak_power: 2.55,
          annual_power_produce: 3519.5865,
        },
      ],
      nb_panels: 7,
      construction_feasibility: "POSSIBLE_BUT_IMPAIRED",
      // construction_feasibility: "NOT_POSSIBLE",
      nb_panels_used: 6.0,
      micro_inverter: false,
      power_construction: "3000",
      power_total: 2.975,
      annual_power_produce_total: 3519.5865,
      prime: 1041.25,
      autoconso: 1935.7725750000002,
      autoconso_economie: 522.6585952500001,
      sell: 1583.8139249999997,
      sell_price: 205.42066607249998,
      estimated_price: {
        TVA: 1.1,
        total_HT: 9050.0,
        total_TTC: 9955.0,
        total_TVA: 905.0,
      },
    };
  }
};

export default function FinalStep({ previousStep, Data }: SubStepperProps) {
  const { data, isSuccess, isError, error, isLoading } = useQuery({
    queryKey: ["fetchGetPrice", Data],
    queryFn: () => fetchGetPrice(Data),
    enabled: true,
    staleTime: 60,
  });

  const [Detail, setDetail] = useState(false);
  const [Composition, setComposition] = useState(false);
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  if (isLoading === true) {
    return (
      <h1 className="m-28 text-4xl font-extrabold leading-none tracking-tight text-gray-800 md:text-5xl lg:text-6xl text-center animate-bounce">
        Nous calculons votre prix . . .
      </h1>
    );
  }

  if (isSuccess === false) {
    return <div>Error</div>;
  }

  // data.construction_feasibility === "NOT_POSSIBLE" ? <>

  // </>

  if (data.construction_feasibility === "NOT_POSSIBLE") {
    return (
      <SideLogo>
        <h1 className="mb-4 sm:mx-0 text-2xl sm:text-8xl font-extrabold leading-none tracking-tight text-gray-800 md:text-5xl lg:text-6xl text-justify">
          Votre simulation est prête :
        </h1>
        <h2 className="m-6 text-4xl font-extrabold leading-none tracking-tight text-red-600 md:text-2xl lg:text-4xl ">
          Vos toitures renseignées ne permettent pas l'installation de panneaux solaires.
        </h2>
        <p className="mb-6 mx-8 sm:mx-0 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
          Ceci est une simulation de prix pour votre installation solaire à titre indicatif selon les informations que vous avez fournies. Pour plus d'informations, veuillez nous contacter.
        </p>
        <div className="grid grid-cols-2 sm:grid-cols-4">
          <Button onClick={() => previousStep()}>Retour</Button>
        </div>
      </SideLogo>
    );
  }
  const rows = genereateAmortizationTable(
    data.annual_power_produce_total,
    data.estimated_price.total_TTC,
    data.prime
  );

  let investissementBalancePositive = getInvestissementBalancePositive(rows);

  return (
    <>
      <SideLogo>
        <h1 className="mb-4 sm:mx-0 text-3xl sm:text-8xl font-extrabold leading-none tracking-tight text-gray-800 md:text-5xl lg:text-6xl text-center">
          Votre simulation est prête :
        </h1>
        <p className="mb-6 mx-8 sm:mx-0 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
          Ceci est une simulation de prix pour votre installation solaire à
          titre indicatif selon les informations que vous avez fournies. Pour
          plus d'informations, veuillez nous contacter :{" "}
        </p>
        <p className="mb-6 mx-8 sm:mx-0 flex flex-col text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
          <div>

            📧  Par email: <a href="mailto:gbary@baryenergie.fr" className="underline">gbary@baryenergie.fr</a>
          </div>
          <div>
            📞 Par téléphone: <a href="tel:0626780176" className="underline">06 26 78 01 76</a>
          </div>
        </p>
        <p className="mb-6 mx-8 sm:mx-0 text-xl font-bold text-gray-500 dark:text-gray-500 italic text-justify">
          Les informations clefs :
        </p>
        <ul className="list-disc">
          <li className="mb-3 mx-8 sm:mx-10 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
            {data.construction_feasibility === "POSSIBLE_BUT_IMPAIRED" ? (
              <>
                Selon les informations fournies, la construction est possible
                mais nous ne pouvons installer que{" "}
                <span className="font-bold">{data.nb_panels_used}</span>{" "}
                panneaux sur votre toiture au lieu de{" "}
                <span className="font-bold">{data.nb_panels}</span> recommandés.
              </>
            ) : (
              <>
                Selon les informations fournies, votre consommation
                d'électricité nécessite l'installation de{" "}
                <span className="font-bold">
                  {data.nb_panels_used} panneaux{" "}
                </span>
                sur votre toiture.
              </>
            )}{" "}
            Les dimensions de votre toit rendent possible l'installation{" "}
            {data.toitures
              .filter((toiture: any) => toiture?.nb_panels_use > 0)
              .map(
                (toiture: any) =>
                  `de ${toiture.nb_panels_use} panneau${toiture.nb_panels_use > 1 ? "x" : ""
                  } orienté${toiture.nb_panels_use > 1 ? "s" : ""
                  } ${orientationToPole(toiture.orientation)}`
              )
              .reduce(
                (
                  acc: string,
                  current: string,
                  index: number,
                  array: string[]
                ) => {
                  if (array.length === 1) {
                    return acc + current + ".";
                  }
                  if (index === array.length - 1) {
                    return acc + " et " + current + ".";
                  }
                  if (index === array.length - 2) {
                    return acc + current;
                  }
                  return acc + current + ", ";
                },
                ""
              )}
          </li>
          <li className="mb-3 mx-8 sm:mx-10 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
            {"La puissance totale installée est de "}
            <span className="font-bold">
              {data.power_total.toFixed(2)}
              {"\u00A0kWc"}
            </span>
            .
          </li>
          <li className="mb-3 mx-8 sm:mx-10 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
            Cette centrale photovoltaïque produira annuellement{" "}
            <span className="font-bold">
              {data.annual_power_produce_total.toFixed(2)}
              {"\u00A0kWh"}
            </span>
          </li>

          <li className="mb-3 mx-8 sm:mx-10 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
            L'installation d'une centrale photovoltaïque de cette puissance engendre une diminution de votre facture annuelle de{" "}
            <span className="font-bold">
              {data.construction_feasibility === "POSSIBLE_BUT_IMPAIRED"
                ? "25 à 40%"
                : "40 à 60%"}
            </span>
          </li>
          <li className="mb-3 mx-8 sm:mx-10 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
            Le retour sur investissement est de{" "}
            <span className="font-bold">
              {`${investissementBalancePositive}\u00A0ans.`}
            </span>{" "}
            Le tableau d'amortissement est disponible en cliquant sur détail.
          </li>
          <li className="mb-3 mx-8 sm:mx-10 text-xl  text-gray-500 dark:text-gray-500 italic text-justify">
            {"Prime d'état pour l'autoconsommation estimée à "}
            <span className="font-bold">{`${data.prime}\u00A0€.`}</span>{" "}
          </li>
        </ul>
        <div className="mb-4 sm:mx-0 text-4xl sm:text-6xl font-extrabold leading-none tracking-tight text-gray-800 text-center rounded-2xl bg-gray-50 p-6 ">
          Estimation : {(data.estimated_price.total_TTC - data.prime).toFixed(2)}€
          <p className="text-sm text-gray-500 dark:text-gray-500 text-center tracking-wider">
            prime déduite
          </p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4">
          {/* <Button onClick={() => previousStep()}>Être recontacté.e</Button> */}
          <Button onClick={() => previousStep()}>Retour</Button>
          <MailButton
            to="gbary@baryenergie.fr"
            subject={`Demande de devis BARY ENERGIE ${Data.name} ${Data.firstname}`}
            body={`Bonjour,\n\nJe souhaite obtenir un devis pour une installation solaire.\n\nVoici mes informations :\n\nNom: ${Data.name}\nPrénom: ${Data.firstname}\nAdresse: ${Data.address}\nCode postal: ${Data.plz}\nVille: ${Data.city}\nType de courant: ${Data.typeCourant}\nConsommation annuelle: ${Data.consommationAnnuelle}\nEmail: ${Data.email}\nTéléphone: ${Data.phone}\n\nMerci de me recontacter pour plus d'informations.\n\n${Data.name}\n ${Data.firstname}`}
          />
          <Button onClick={() => { setDetail(!Detail); setComposition(false) }}>Tableau d'amortissement</Button>
          <Button onClick={() => { setComposition(!Composition); setDetail(false) }}>Détail de l'installation</Button>
        </div>
      </SideLogo>
      {Detail && (
        <div className="m-12">
          <AmortizationTable rows={rows} />
        </div>
      )}
      {Composition && (
        <>
          <div className=" flex flex-col sm:flex-row sm:my-20">
            <div className="sm:basis-1/4">

            </div>
            <div className="sm:px-32 sm:basis-3/4">

              <h1 className="m-6 mx-8 sm:mx-0 text-xl font-bold text-gray-500 dark:text-gray-500 italic">
                Composition de votre installation photovoltaïque :
              </h1>
              <ul className="list-disc mx-8 sm:mx-10 text-lg font-normal text-gray-500 dark:text-gray-500 italic text-justify">
                <li>1 onduleur centralisé HUAWEI de la gamme SUN2000. (<a href="/datasheet/sun2000-2-6ktl-l1_2.pdf" target="_blank" className="underline">voir fiche technique</a>)</li>
                <li>{data.nb_panels_used} panneaux photovoltaïques DUALSUN TOPCON 425Wc (<a href="/datasheet/DUALSUN_HALF_CUT_TOPCON_425Wc.pdf" target="_blank" className="underline">voir fiche technique</a>)</li>
                {data.micro_inverter && <li>{data.nb_panels_used} micro-onduleurs ENPHASE IQ8MC (<a href="/datasheet/IQ8SE-DS-0074-01-FR-INT-2023-03-29.pdf" target="_blank" className="underline">voir fiche technique</a>) (si ombre)</li>}
                <li>Système d'intégration tuile mécanique (K2 system)</li>
                <li>Système d'intégration ardoise (K2 system)</li>
                <li>Système d'intégration bac acier (joriside)</li>
                <li>Système d'intégration toiture terrasse (Esdec)</li>
              </ul>
            </div>
          </div>

        </>)}

    </>
  );
}
