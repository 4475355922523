import { DEBUG } from "../MultiStep";

interface FeatureCollection {
  type: string;
  version: string;
  features: Feature[];
  attribution: string;
  licence: string;
  query: string;
  limit: number;
}

interface Feature {
  type: string;
  geometry: Geometry;
  properties: Properties;
}

interface Geometry {
  type: string;
  coordinates: number[];
}

interface Properties {
  label: string;
  score: number;
  housenumber: string;
  id: string;
  name: string;
  postcode: string;
  citycode: string;
  x: number;
  y: number;
  city: string;
  district?: string;
  context: string;
  type: string;
  importance: number;
  street: string;
  oldcitycode?: string;
  oldcity?: string;
}

type fetchAddressReturnType = {
  label: string;
  value: {
    address: string;
    plz: string;
    city: string;
  };
}[];
export const fetchAddress = async (
  address: string,
  limit: number = 7
): Promise<fetchAddressReturnType> => {
  let data: FeatureCollection;
  if (!DEBUG) {
    if (address.length < 3) {
      return [];
    }
    const url = `${
      process.env.REACT_APP_URL_BACKEND
    }/https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(
      address
    )}&limit=${limit}`;
    const response = await fetch(url, { mode: "cors" });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    data = await response.json();
  } else {
    // Simulated response
    data = {
      type: "FeatureCollection",
      version: "draft",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [1.458466, 43.649025],
          },
          properties: {
            label: "25 Rue Frédéric Chopin 31200 Toulouse",
            score: 0.9776199999999999,
            housenumber: "25",
            id: "31555_3572_00025",
            name: "25 Rue Frédéric Chopin",
            postcode: "31200",
            citycode: "31555",
            x: 575605.92,
            y: 6284545.64,
            city: "Toulouse",
            context: "31, Haute-Garonne, Occitanie",
            type: "housenumber",
            importance: 0.75382,
            street: "Rue Frédéric Chopin",
          },
        },
      ],
      attribution: "BAN",
      licence: "ETALAB-2.0",
      query: "25 rue frédéric chopin 31200 Toulouse",
      limit: 7,
    };
  }
  return data.features.map((feature) => {
    const { name, postcode, city } = feature.properties;
    return {
      label: `${name} ${postcode} ${city}`,
      value: { address: name, plz: postcode, city },
    };
  });
};
