type ProgressBarProps = {
  step: number;
  steps: string[];
};

export default function ProgressBar({ step, steps }: ProgressBarProps) {
  const totalSteps = steps.length;
  const percentage = (step / totalSteps) * 100 || 1;

  return (
    <div className="w-full bg-gray-300 h-2.5 sticky top-0">
      <div
        className="bg-green rounded-r h-2.5 "
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
}
