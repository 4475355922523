import { PropsWithChildren } from "react";
import LabelInput from "./LabelInput";
import { FieldError } from "react-hook-form";
import { cn } from "../../lib/utils";

interface FieldProps {
  label: string;
  error?: FieldError | any;
  className?: string;
}

export default function Field({
  label,
  error,
  children,
  className,
}: PropsWithChildren<FieldProps>) {
  return (
    <div className={cn("w-full md:w-1/2 px-3 mb-6 md:mb-0", className)}>
      <LabelInput>{label}</LabelInput>

      {children}

      {error && <p className="text-red-500 text-xs italic">{error.message}</p>}
    </div>
  );
}
