import { forwardRef, SelectHTMLAttributes } from "react";

interface Option {
  label: string; // Texte à afficher
  value: string | number; // Valeur de l'option
}
export interface SelectInputProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[];
}

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
  ({ options, ...props }, ref) => {
    return (
      <select
        className={
          "block w-full bg-gray-200 text-gray-700 border rounded-xl py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        }
        {...props}
        ref={ref}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }
);

export default SelectInput;
