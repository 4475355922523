import Input from "../form-components/Input";
import { SubStepperProps } from "../type";
import Button from "../form-components/Button";
import { useForm } from "react-hook-form";
import Field from "../form-components/Field";
import { SideLogo } from "../form-components/SideLogo";

export default function InfoStep({
  nextStep,
  Data,
  updateData,
}: SubStepperProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    updateData({ ...Data, ...data });
    nextStep();
  };

  return (
    <SideLogo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap mb-6">
          <Field label="Nom" error={errors?.name}>
            <Input
              {...register("name", {
                required: "Ce champ est obligatoire",
              })}
              placeholder="Nom"
              isError={!!errors?.name}
              defaultValue={Data.name}
            />
          </Field>
          <Field label="Prénom" error={errors?.firstname}>
            <Input
              {...register("firstname", {
                required: "Ce champ est obligatoire",
              })}
              placeholder="Prénom"
              isError={!!errors?.firstname}
              defaultValue={Data.firstname}
            />
          </Field>
          <Field label="email" error={errors?.email}>
            <Input
              {...register("email", {
                required: "Ce champ est obligatoire",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Adresse mail invalide",
                },
              })}
              placeholder="Email"
              isError={!!errors?.email}
              defaultValue={Data.email}
            />
          </Field>
          <Field label="Numéro de Téléphone" error={errors?.phone}>
            <Input
              {...register("phone", {
                required: "Ce champ est obligatoire",
                pattern: {
                  value: /^(?:(?:\+|00)33|0)\d{9}$/,
                  message: "Numéro invalide",
                },
              })}
              placeholder="N° de numéro"
              isError={!!errors?.phone}
              defaultValue={Data.phone}
            />
          </Field>
        </div>
        <div className="grid">
          <Button type="submit">Suivant</Button>
        </div>
      </form>
    </SideLogo>
  );
}
