export function SideLogo({ children }: { children: React.ReactNode }) {
    return (
        <div className=" flex flex-col sm:flex-row sm:my-20">
        <div className="sm:basis-1/4  ">
          <img src="bary_energie.jpg" alt="logo" />
        </div>
        <div className="sm:px-32 sm:basis-3/4">
        {children}
        </div>
      </div>
    );
}