import { forwardRef, useEffect, useState } from "react";
import Input, { InputProps } from "./Input";
import { cn } from "../../lib/utils";

export interface InputSuggestionProps extends InputProps {
  suggestions?: {
    label: string;
    value: any;
    icon?: React.ReactNode;
  }[];
  onClickSuggestion?: (value: any) => void;
  icon?: React.ReactNode;
}

export const InputSuggestion = forwardRef<
  HTMLInputElement,
  InputSuggestionProps
>(({ isError, suggestions = [], onClickSuggestion, icon, ...props }, ref) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  return (
    <div className="mb-3">
      <Input
        ref={ref}
        {...props}
        className={cn(
          "m-0 ",
          showSuggestions &&
            suggestions.length > 0 &&
            "rounded-none rounded-t-xl"
        )}
        icon={showSuggestions && icon}
        onChange={(e) => {
          props.onChange && props.onChange(e);
          setShowSuggestions(true);
        }}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="border border-gray-300 rounded-b-xl">
          {suggestions?.map((suggestion, index) => (
            <li
              key={suggestion.label}
              className={`${
                index !== suggestions.length - 1 && "border"
              } border-gray-200 hover:bg-gray-200 p-3`}
              onClick={() => {
                onClickSuggestion && onClickSuggestion(suggestion.value);
                setShowSuggestions(false);
              }}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});

export default InputSuggestion;
