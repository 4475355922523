import { InputHTMLAttributes, forwardRef } from "react";
import { cn } from "../../lib/utils";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
  icon?: React.ReactNode;
}

const baseClasses =
  "appearance-none block w-full bg-gray-200 text-gray-700 border rounded-xl py-3 px-5 mb-3 leading-tight focus:outline-none focus:bg-white";
const errorClasses = "border-red-500";
const iconPaddingClasses = "pl-10";

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ isError, icon, className, ...props }, ref) => {
    return (
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {icon}
          </div>
        )}
        <input
          className={cn(
            baseClasses,
            isError && errorClasses,
            icon && iconPaddingClasses,
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);

export default Input;
